<template>
  <div class="q-pa-md">
    <q-breadcrumbs
      :class="$q.dark.isActive ? 'text-indigo-2' : 'text-indigo-10'"
    >
      <template v-slot:separator>
        <q-icon size="2rem" name="chevron_right" />
      </template>
      <q-breadcrumbs-el label="Recursos Humanos" icon="mdi-account-group" />
      <q-breadcrumbs-el label="Certificados del personal" />
    </q-breadcrumbs>
  </div>

  <div class="body-container q-mx-auto">
    <h5>Certificados</h5>
    <q-btn flat color="primary" @click="addCertificate">
      Agregar nuevo certificado
    </q-btn>
    <div class="row q-mt-md">
      <div class="col-12">
        <q-markup-table separator="cell" flat bordered>
          <thead>
            <tr>
              <th class="text-center">Documento</th>
              <th class="text-left">Título</th>
              <th class="text-left">Nombre completo</th>
              <th class="text-left">Anexos</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(document, i) in documents" :key="i">
              <td class="text-center">
                <q-avatar
                  rounded
                  @click="showDocument(document, document.file)"
                >
                  <img :src="require(`@/assets/icons/pdf-96.png`)" />
                </q-avatar>
              </td>
              <td
                class="text-left"
                style="
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 150px;
                "
              >
                {{ document.name }}
              </td>
              <td
                class="text-left"
                style="
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  max-width: 150px;
                "
              >
                {{ document.expand.user_id.names }}
                {{ document.expand.user_id.p_lastname }}
                {{ document.expand.user_id.m_lastname }}
              </td>
              <td class="text-left">
                <div v-for="(item, j) in document.related_files" :key="j">
                  <a href="#" @click="showDocument(document, item)">{{
                    item
                  }}</a>
                </div>
              </td>
              <td class="text-right">
                <q-btn
                  icon="delete"
                  flat
                  round
                  color="negative"
                  @click="deleteDocument(document)"
                />
              </td>
            </tr>
            <tr v-if="documents.length <= 0">
              <td colspan="4">
                <q-inner-loading :showing="loading">
                  <q-spinner-dots color="primary" size="30"></q-spinner-dots>
                </q-inner-loading>
                <div
                  class="text-caption text-center text-grey"
                  v-if="documents.length === 0"
                >
                  No hay resultados
                </div>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
  </div>
</template>
<script>
import pb from "@/pocketbase.js";
import { useQuasar } from "quasar";
import ShowDocument from "@/components/Paystub/ShowDocument.vue";
import CreateCertificate from "@/components/Certificate/CreateCertificate.vue";

export default {
  components: {
    ShowDocument,
    CreateCertificate,
  },
  data: () => ({
    $q: useQuasar(),
    loading: false,
    documents: [],
  }),
  mounted() {
    this.getDocuments();
  },
  methods: {
    async deleteDocument(document) {
      this.$q
        .dialog({
          title: "¿Eliminar?",
          message: `Estas seguro que quieres eliminar el certificado <b>${document.name.toUpperCase()}</b> del usuario <b>${document.expand.user_id.names.toUpperCase()} ${document.expand.user_id.p_lastname.toUpperCase()} ${document.expand.user_id.m_lastname.toUpperCase()}</b>`,
          html: true,
          ok: {
            color: "primary",
          },
          cancel: {
            color: "negative",
          },
        })
        .onOk(async () => {
          try {
            await pb.collection("user_certificate").delete(document.id);
            this.$q.notify({
              type: "positive",
              message: "Elemento eliminado",
              caption: document.id,
            });
            this.getDocuments();
          } catch (ex) {
            this.$q.notify({
              type: "negative",
              message: ex.message,
            });
          }
        });
    },
    addCertificate() {
      this.$q
        .dialog({
          component: CreateCertificate,
        })
        .onDismiss(() => {
          this.getDocuments();
        });
    },
    async showDocument(document, filename) {
      if (
        document.observations !== "" &&
        document.observations !== null &&
        document.observations !== undefined
      ) {
        this.$q
          .dialog({
            color: "negative",
            title: "Observación",
            message: document.observations,
            persistent: true,
            html: true,
          })
          .onDismiss(async () => {
            const fileToken = await pb.files.getToken();
            this.$q
              .dialog({
                component: ShowDocument,
                componentProps: {
                  url: `${pb.baseUrl}/api/files/pbc_2531831130/${document.id}/${filename}?token=${fileToken}`,
                },
              })
              .onDismiss(() => {});
          });
      } else {
        const fileToken = await pb.files.getToken();
        this.$q
          .dialog({
            component: ShowDocument,
            componentProps: {
              url: `${pb.baseUrl}/api/files/pbc_2531831130/${document.id}/${document.file}?token=${fileToken}`,
            },
          })
          .onDismiss(() => {});
      }
    },
    async getDocuments() {
      try {
        this.loading = true;
        const response = await pb.collection("user_certificate").getFullList({
          expand: "user_id",
          sort: "-created",
        });
        this.documents = response;
      } catch (ex) {
        this.$q.notify({
          type: "negative",
          message: ex.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
