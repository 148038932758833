<template>
  <q-dialog persistent v-model="dialog">
    <q-card style="max-width: 800px; width: 500px">
      <q-bar class="bg-primary text-white">
        <q-space />
        <q-btn dense flat icon="close" v-close-popup />
      </q-bar>
      <q-card-section>
        <div class="text-h6">Subir nuevo certificado</div>
      </q-card-section>
      <q-card-section>
        <q-form @submit.prevent="onSubmit" @reset="onReset" ref="form">
          <div class="row">
            <q-input
              class="col-12"
              v-model="name"
              :dense="dense"
              outlined
              label="Titulo del certificado"
              :rules="[(val) => !!val || 'Necesita agregar un título']"
              autocomplete="ON"
              :disable="loading"
            />
            <q-select
              class="col-12"
              v-model="user_id"
              :dense="dense"
              outlined
              label="Seleccione el nombre del personal"
              :rules="[(val) => !!val]"
              :options="staff"
              option-value="id"
              option-label="dni_fullname"
              emit-value
              map-options
              input-debounce="100"
              @filter="filterFn"
              @filter-abort="abortFilter"
              use-input
              hide-selected
              fill-input
              :disable="loading"
            />
            <q-input
              type="date"
              class="col-6 q-pr-sm q-mb-md"
              v-model="from"
              :dense="dense"
              outlined
              label="Desde"
              autocomplete="ON"
              :disable="loading"
            />
            <q-input
              type="date"
              class="col-6 q-pl-sm q-mb-md"
              v-model="to"
              :dense="dense"
              outlined
              label="Hasta"
              autocomplete="ON"
              :disable="loading"
            />
            <q-file
              type="file"
              class="col-12"
              v-model="file"
              :dense="dense"
              outlined
              label="Certificado"
              :rules="[
                (val) =>
                  !!val || 'Debe seleccionar el documento del certificado',
              ]"
              :disable="loading"
              accept="application/pdf"
            />
            <q-file
              type="file"
              class="col-12"
              v-model="related_files"
              :dense="dense"
              multiple
              outlined
              label="Anexos"
              :disable="loading"
              accept="application/pdf"
            />
            <div class="col-12 q-mt-md">
              <q-btn
                type="submit"
                color="primary"
                outline
                icon="save"
                label="Guardar"
                :disable="loading"
              />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-card>
  </q-dialog>
</template>
<script>
import pb from "@/pocketbase.js";
import { useQuasar } from "quasar";

export default {
  data: () => ({
    $q: useQuasar(),
    loading: false,
    dialog: true,
    dense: true,
    staff: [],
    search: "",
    name: "",
    user_id: "",
    from: "",
    to: "",
    file: null,
    related_files: null,
  }),
  mounted() {},
  methods: {
    async onSubmit() {
      if (!this.$refs.form.validate()) return;
      try {
        this.loading = true;
        const form = new FormData();
        form.append("name", this.name);
        form.append("user_id", this.user_id);
        form.append("created_by", pb.authStore.model.id);
        form.append("file", this.file);
        this.related_files.forEach((item) => {
          form.append("related_files", item);
        });
        const response = await pb.collection("user_certificate").create(form);
        this.$q.notify({
          type: "positive",
          message: "Registro guardado correctamente",
          caption: response.id,
        });
        this.dialog = false;
      } catch (ex) {
        this.$q.notify({
          type: "negative",
          message: ex.message,
        });
      } finally {
        this.loading = false;
      }
    },
    onReset() {},
    async filterFn(val, update, abort) {
      try {
        if (val !== "") {
          const resultList = await pb.collection("view_user").getList(1, 5, {
            filter: `dni_fullname ~ '${val}'`,
          });
          this.staff = resultList.items;
          console.log(resultList);
          update();
          return;
        }
        abort();
      } catch (ex) {
        console.log(ex.message, "=>", ex);
      }
    },
    async abortFilter() {},
    async getStaff(val) {
      try {
        const resultList = await pb.collection("view_user").getList(1, 5, {
          filter: `dni_fullname ~ '${val}'`,
        });
        this.staff = resultList.items;
        console.log(resultList);
      } catch (ex) {
        console.log(ex.message, "=>", ex);
      }
    },
  },
};
</script>
