<template>
  <div class="q-pa-md">
    <div class="body-container q-mx-auto">
      <h5>Certificados</h5>

      <div class="row q-mt-md">
        <div class="col-12">
          <q-markup-table separator="cell" flat bordered>
            <thead>
              <tr>
                <th class="text-center">Documento</th>
                <th class="text-left">Título</th>
                <th class="text-left">Anexos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(document, i) in documents" :key="i">
                <td class="text-center">
                  <q-avatar
                    rounded
                    @click="showDocument(document, document.file)"
                  >
                    <img :src="require(`@/assets/icons/pdf-96.png`)" />
                  </q-avatar>
                </td>
                <td
                  class="text-left"
                  style="
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 300px;
                  "
                >
                  {{ document.name }}
                </td>
                <td class="text-left">
                  <div v-for="(item, j) in document.related_files" :key="j">
                    <a href="#" @click="showDocument(document, item)">{{
                      item
                    }}</a>
                  </div>
                </td>
              </tr>
              <tr v-if="documents.length <= 0">
                <td colspan="4">
                  <q-inner-loading :showing="loading">
                    <q-spinner-dots color="primary" size="30"></q-spinner-dots>
                  </q-inner-loading>
                  <div
                    class="text-caption text-center text-grey"
                    v-if="documents.length === 0"
                  >
                    No hay resultados
                  </div>
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pb from "@/pocketbase.js";
import { useQuasar } from "quasar";

import ShowDocument from "@/components/Paystub/ShowDocument.vue";

export default {
  components: { ShowDocument },
  data: () => ({
    page: 1,
    perPage: 5,
    $q: useQuasar(),
    find: {
      all: true,
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
    },
    loading: false,
    nomore: false,
    field: {
      years: [2024],
      months: [
        { value: 0, label: "Enero" },
        { value: 1, label: "Febrero" },
        { value: 2, label: "Marzo" },
        { value: 3, label: "Abril" },
        { value: 4, label: "Mayo" },
        { value: 5, label: "Junio" },
        { value: 6, label: "Julio" },
        { value: 7, label: "Agosto" },
        { value: 8, label: "Setiembre" },
        { value: 9, label: "Octubre" },
        { value: 10, label: "Noviembre" },
        { value: 11, label: "Diciembre" },
      ],
    },
    documents: [],
  }),
  beforeMount() {
    document.title = "Celicon APP - Boletas de pago";
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    async getDocuments() {
      try {
        this.loading = true;
        const response = await pb.collection("user_certificate").getFullList({
          filter: `user_id = '${pb.authStore.model.id}'`,
        });
        this.documents = response;
      } catch (ex) {
        this.$q.notify({
          type: "negative",
          message: ex.message,
        });
      } finally {
        this.loading = false;
      }
    },
    formatDate(year, month) {
      const date = new Date(year, month);
      const format = date
        .toLocaleDateString("es-ES", {
          // weekday: 'long',
          month: "long",
          year: "numeric",
        })
        .toUpperCase();
      return format;
    },
    async showDocument(document, filename) {
      if (
        document.observations !== "" &&
        document.observations !== null &&
        document.observations !== undefined
      ) {
        this.$q
          .dialog({
            color: "negative",
            title: "Observación",
            message: document.observations,
            persistent: true,
            html: true,
          })
          .onDismiss(async () => {
            const fileToken = await pb.files.getToken();
            this.$q
              .dialog({
                component: ShowDocument,
                componentProps: {
                  url: `${pb.baseUrl}/api/files/pbc_2531831130/${document.id}/${filename}?token=${fileToken}`,
                },
              })
              .onDismiss(() => {});
          });
      } else {
        const fileToken = await pb.files.getToken();
        this.$q
          .dialog({
            component: ShowDocument,
            componentProps: {
              url: `${pb.baseUrl}/api/files/pbc_2531831130/${document.id}/${document.file}?token=${fileToken}`,
            },
          })
          .onDismiss(() => {});
      }
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>
